.BgLine {
  background-color: linear-gradient(to right, #EE7E40, #EC323F);
  width: 100%;
  border-width: 2%;
  border-image-source: linear-gradient(to right, #EE7E40, #EC323F);
  border-image-slice: red;
  border-style: solid;
}

.ProjectInfoBoundary {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* font-size: 1vw; */
}

.IconDimen {
  height: 2vw;
  width: 2vw
}

.TabHeader {
  font-size: 19px;
  margin: 10px 0px 6px 0px;
}

.ProjectInfomainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5vw;
}

.ProjectImage {
  width: 6vw;
  height: 6vw;
}

.ButtonRow {
  display: flex;
  align-items: center;
}

.IconsRow {
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.IconsColumn {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.ProjectName {
  /* font-size: 3vw; */
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.NavLinkRow {
  /* height: 3vw; */
  /* font-size: 1vw; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* font-size: 1.5vw; */
  /* width: 15vw; */
}

.NavLink {
  color: white;
  width: 5vw;
}

.StatusContainer {
  padding: 4%;
  background-color: rgba(62, 51, 71, 0.7);
  border-radius: 8px;
  margin-bottom: 10px;
  /* width: 100% !important; */
  /* height: 12vw */
}

.StatusHeaderContainer {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* font-size: 1.5vw; */
  height: 5vw;
  width: 35vw;
}

.ProjectInfoDetail {
  /* font-size: 1vw; */
}
.projectdetail-lefttable{
  font-size: 14px;
}
.projectdetail-righttable{
  color: gold;
  font-size: 14px;
}


.StatusHeader {
  /* border-bottom: 2px dotted rgba(255, 255, 255, 0.3) */
}

.ProgressbarHeaderContainer {
  color: white;
  flex: row;
  justify-content: center;
  align-items: center;
  /* height: 4vw; */
  /* width: 35vw; */
}

.ProgressbarHeader {
  /* font-size: 1vw; */
}

.Progressbar {
  background-color: linear-gradient(to right, #EE7E40, #EC323F);
  color: #EC323F;
  background-color: #EE7E40;
}

.PrecentageText {
  /* font-size: 0.8vw; */
}

.Completionbar {
  /* font-size: 0.8vw; */
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.ConnectWalletButton {
  display: flex;
  flex-direction: column;
  justify-content: end;
  /* height: 5vw; */
  /* width: 11vw; */
  /* font-size: 1.5vw; */
}

.NavbarTabHeaderContainer {
  height: 5vw;
  display: flex;
  justify-content: space-between;
  width: 30vw;
  align-content: flex-end;
}

.TabContainer {
  justify-content: left;
  border-color: white;
  border-radius: 10px;
  border-width: 5px;
}

.TabContainerRow {
  /* border-bottom: 2px dotted #ffffff4d; */
  display: flex;
  flex-direction: row;
  width: 60vw
}

.divider-vertical{
  border-left: 2px dotted gray;
  width:4% !important;
  /* margin-bottom: 0.8rem;  */
  margin-top: 0.8rem;
}

.divider-horizontal  {
  display: none;
}

@media screen and (max-width : 1440px) {
  .ProjectInfomainContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 5vw;
  }
}

@media screen and (max-width : 768px) {

  .ProjectInfomainContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 7vw;
  }

  .TabContainerRow {
    /* border-bottom: 2px dotted rgba(255, 255, 255, 0.3); */
    display: flex;
    flex-direction: row;
    width: 60vw;
    /* font-size: 1vw; */
  }

  .NavLinkRow {
    /* height: 5vw; */
    /* font-size: 1vw; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* font-size: 1.5vw; */
    /* width: 15vw; */
  }

  .NavLink {
    color: white;
    width: 5vw;
    /* font-size: 2vw; */
  }

  .ProjectInfoDetail {
    /* font-size: 1vw; */
    height: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

  .NavbarTabHeaderContainer {
    background-color: transparent;
    height: 5vw;
    display: flex;
    justify-content: space-between;
    width: 40vw;
    align-content: flex-end;
    /* font-size: 1.2vw; */
  }

  .divider-horizontal   {
    display: contents;
  }
  .divider-horizontal > hr  {
    border-top: 2px dotted gray
  }

}

@media screen and (max-width : 992px) {
  .TabHeader {
    /* font-size: 2vw; */
  }

  .ProjectInfoDetail {
    /* font-size: 1.5vw; */
  }

  .ConnectWalletButton {
    display: flex;
    flex-direction: column;
    justify-content: end;
    /* height: 5vw;
    width: 15vw; */
    /* font-size: 1.5vw; */
  }
}

@media screen and (max-width : 576px) {
  .ProjectInfomainContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90vw;
    align-content: space-around;
    align-items: end;
    height: 25vw
  }

  .ProjectName {
    /* font-size: 4vw; */
    font-weight: bold;
    height: 7vw;
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .NavLinkRow {
    height: 5vw;
    /* font-size: 1vw; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* font-size: 1vw; */
    width: 90vw;
  }

  .NavLink {
    color: white;
    width: 15vw;
    height: 5vw;
    /* font-size: 2vw; */
  }

  .PImageIconContainer {
    /* height: 20vw; */
  }

  .IconsColumn {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .ImageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* background-color: wheat; */
  }

  .ProjectImage {
    width: 15vw;
    height: 15vw;
  }

  .IconsRow {
    width: 90vw;
    height: 5vw;
  }

  .StatusRow {
    /* width: 80vw;
    height: 35vw; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  /* .StatusContainer {
    padding: 4%;
    background-color: rgba(62, 51, 71, 0.7);
    border-radius: 8px;
    width: 70vw;
    height: 30vw
  } */

  .StatusHeaderContainer {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* font-size: 1.5vw; */
    height: 5vw;
    width: 70vw;
  }

  /* .StausHeader {
    border-bottom: 2px dotted rgba(255, 255, 255, 0.3);
    height: 6vw;
    width: 100vw;
  } */

  .ProgressbarHeaderContainer {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 20vw;
    width: 80vw;
  }

  .ProgressbarHeader {
    /* font-size: 3vw; */
    width: 90vw;
    height: 5vw;
  }

  .ProgressBarRow {
    width: 90vw
  }

  .Progressbar {
    background-color: linear-gradient(to right, #EE7E40, #EC323F);
    color: #EC323F;
    /* width: 50vw; */
    height: 5vw
  }

  .PrecentageText {
    /* font-size: 2vw; */
  }

  .Completionbar {
    /* font-size: 2vw; */
    display: flex;
    flex-direction: row;
    justify-content: end;
  }

  .ProjectInfoDetail {
    /* font-size: 3vw; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 15vw;
    align-items: center;
  }


  .ConnectWalletButton {
    display: flex;
    flex-direction: column;
    justify-content: end;
    /* font-size: 3vw; */
  }

  .NavbarTabHeaderContainer {
    height: 8vw;
    display: flex;
    justify-content: space-between;
    width: 80vw;
    align-content: flex-end;
    /* font-size: 2vw; */
  }

  .TabContainerRow {
    /* border-bottom: 2px dotted rgba(255, 255, 255, 0.3); */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    /* font-size: 1.8vw; */
  }
}

.TabsView {
  color: white;
  cursor: pointer;
}
.TabsView:hover {
  color: white;
  cursor: pointer;
}
.TabsView.nav-link.active {
  background: linear-gradient(to right, #EE7E40, #EC323F);
  color: white;
}