.SocialMediaContainer{
    min-height: 33vw;
}
.SocialIcon{
    color: white;
    font-size: 32px;
    background: #4f4a56;
    border-radius: 50%;
    padding: 3px;
}
.FieldHeaderStyle{
    display: flex;
    align-items: center;
}