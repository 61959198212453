.ApplyNowMainContainer {
  min-height: 70vh;
  margin-top: 2vh;
  margin-bottom: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.ApplyNowMainHeading {
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 4.5vh;
}

.ApplyNowContent {
  color: white;
  text-align: center;
  font-size: 2.7vh;
}

.ApplyNowButton {
  width: 100%;
  /* height: 7vh; */
  height: 50px;
  background: linear-gradient(to right, #EE7E40, #EC323F);
  border: none;
  z-index: 4;
}
.ApplyNowButton :hover{
  opacity: 1;
    transition: 1s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    z-index: 999;
    overflow: hidden;
    /* padding: 1px;  */
}
.ApplyNowButton :hover {
 
}


.ApplyNowButtonRow {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 10vh;
  /* background-image: url('../../Assets/globe.png'); */
  background-repeat: no-repeat;
  background-position-x: center;
  z-index: 2;
}
.ApplyNowButtonRow img{
  height: 400px;
}
.imgs{
  position: absolute;
  z-index: 1;
}
@media screen and (width : 2560px) {
.ApplyNowButtonRow img{
  display: none;
}
}
@media screen and (max-width : 768px) {
  .ApplyNowMainHeading {
    font-size: 4vh;
  }

  .ApplyNowContent {
    font-size: 2.5vh;
  }
  .ApplyNowButtonRow img{
    display: none;
  }
}

@media screen and (max-width : 576px) {
  .ApplyNowMainHeading {
    font-size: 3vh;
  }

  .ApplyNowContent {
    font-size: 2vh;
  }

  .ApplyNowButtonRow {
    background-size: contain;
  }
}