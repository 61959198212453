.StakingStatusIconCol {
    display: flex;
}

.StakingStatusDotsContainer {
    align-items: center;
}

.StakingStatusIconContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.StakingStatusIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    height: 6vh; 
    width: 6vh;
    text-align: center;
}

.StakingStatusIconHeading {
    color: white;
    font-size: 1.5vh;
}
@media (orientation: landscape) {
    .StakingStatusIconHeading {
        color: white;
        font-size: 15px;
        margin: 10px;
    }
}

@media screen and (max-width : 768px) {
    .StakingStatusIcon {
        height: 8vh;
        width: 8vh;
    }
}