.AboutUsContainer{
    color: white;
    margin-top: 5%;
    margin-bottom: 8%;
    min-height: 22vw;
}

.AboutUsHeadingRow{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 2vw;
    height: 10vw;
    align-items: center;
    align-content: center; 
}

.AboutUsHeadingText{
    width: 72vw;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
}

.AboutUsTextRow{
    /* height: 20vw; */
    /* align-items: center; */
    /* align-content: end; */
    font-size:1vw;
}

@media screen and (max-width : 768px) {
   
    .AboutUsHeadingText{
        width: 74vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
  }

  @media screen and (max-width : 576px) {
   
    .AboutUsHeadingText{
        width: 78vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .AboutUsTextRow{
        height: 50vw;
        width: 80vw;
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-content: space-between;
        font-size:2vw;
    }

    
    .AboutUsScreenHandler{
        height: 60vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
    }

    
    
  }