.FeaturesTabContainer{
    background: rgba(62, 51, 71, 0.7);
    border-radius: 20px;
    min-height: 416px;
    padding: 20px 
}

.FeaturesLowerTabContainer{
    background: rgba(62, 51, 71, 0.7);
    border-radius: 20px;
    min-height: 580px;
    padding: 20px 
}

.FeatureFieldHeaderStyle{
    color: gold;
}