.FooterContainer {
    margin-bottom: 10vh;
    margin-top: 10vh;
    display: flex;
}

.FooterLogo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.FooterLogoText {
    color: #9b7bb7;
    font-size: 0.8vw;
    text-align: center;
}

.FooterTermsContainer {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.FooterMediaContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
@media (max-height: 600px) {
    .FooterTermsContainer {
        background-color: #1B1124 !important;
    }
    .FooterMediaContainer {
        background-color: #1B1124 !important;
    }
}

.FooterMediaIcons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.FooterMediaText {
    font-size: 1.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    width: 100%;
}

.TermsText {
    font-size: 1.2vw;
    padding-right: 1vw;
    color: white;
    text-decoration: none;
}

.TermsText:hover{
    color: #ffd700 !important;
}

.CookiesText {
    font-size: 1.2vw;
    padding-left: 1vw;
    color: white;
    text-decoration: none;
    border-bottom: none;
}
.CookiesText:hover{
    color: #ffd700 !important;
}

@media screen and (max-width: 1200px) {
   
    .FooterLogoText {
        color: #9b7bb7;
        font-size: 1vw;
        text-align: center;
    }
}

@media screen and (max-width: 992px) {
    .FooterMediaIcons {
        width: auto;
    }

    .FooterLogoText {
        color: #9b7bb7;
        font-size: 2vw;
        text-align: center;
    }

    .FooterMediaText {
        width: auto;
    }
}

@media screen and (max-width: 768px) {

    .FooterContainer {
        height: 40vh;
    }

    .FooterLogoText {
        color: #9b7bb7;
        font-size: 2vw;
        text-align: center;
    }

    .FooterMediaText {
        font-size: 5vw;
    }

    .FooterMediaText {
        align-items: center;
    }

    .TermsText {
        font-size: 3vw;
        padding-right: 0;
    }

    .CookiesText {
        font-size: 3vw;
        padding-left: 0;
    }
}

@media screen and (max-width: 576px) {

    .FooterContainer {
        margin: 0;
    }

    .FooterLogoText {
        color: #9b7bb7;
        font-size: 3vw;
        text-align: center;
    }

    .FooterMediaText {
        font-size: 5vw;
        align-items: center;
        padding-bottom: 3vw;
    }

    .TermsText {
        font-size: 4vw;
    }

    .CookiesText {
        font-size: 4vw;
    }

    .FooterMediaIcons {
        justify-content: space-around;
    }
}