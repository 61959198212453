.FooterIcon {
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5vw;
    height: 2.5vw;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 1vw;
}

.FooterIcon:hover{
    opacity: 1;
    transition: 1s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    z-index: 999;
    overflow: hidden;
    /* padding: 1px;  */
    padding-right: 2px;

}

@media screen and (max-width: 768px) {
    .FooterIcon {
        width: 7vw;
        height: 7vw;
    }
}
@media screen and (max-width: 576px) {
    .FooterIcon {
        width: 10vw;
        height: 10vw;
    }
}