.ProjectsMainHeading {
    color: white;
    /* font-size: 3vw; */
    font-weight: bold;
}

@media screen and (max-width: 576px) {
    .ProjectsMainHeading {
        /* font-size: 6vw; */
    }
}