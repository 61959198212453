.HomeButton {
    border: 2px solid white;
    color: white;
    border-radius: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    font-size: 70%;
}

.HomeButton:hover {
    background: linear-gradient(to right, #EE7E40, #EC323F);
    border: 2px solid linear-gradient(to right, #EE7E40, #EC323F);
    color: white;
}

.HomeButtonFocused {
    border: 2px solid white;
    color: white;
    border-radius: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    font-size: 70%;
    background: linear-gradient(to right, #EE7E40, #EC323F);
    border: 2px solid linear-gradient(to right, #EE7E40, #EC323F);
    color: white;
}

.HomeButtonFocused:hover {
    border: 2px solid white;
    color: white;
    border-radius: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    font-size: 70%;
    background: linear-gradient(to right, #EE7E40, #EC323F);
    border: 2px solid linear-gradient(to right, #EE7E40, #EC323F);
    color: white;
}

.HomeButtonContianer {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width : 1200px)
{
  
}

@media screen and (max-width : 768px)
{
    .HomeButton {
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
}
