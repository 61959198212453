.modal-open{
    padding-right: 0 !important;
}
.HeaderNavItem {
    font-size: 1.2vw;
}

.ErrorModalIcon {
    width: 2vw;
    height: 2vw;
}

.custom-modal-style {
    width: auto;
    height: auto;
}

.modalContainer {
    width: auto;
    background-color: #1B1124;
    color: white;
    overflow: hidden;
    font-size: 1vw;
}

.HeaderNavContainer {
    align-items: center;
    justify-content: space-around;
    width: 80%;
}

.HeaderLogo {
    width: 8vw;
    height: auto;
}

.HeaderNavItem {
    height: 4vh;
    display: flex;
    align-items: center;
}
.HeaderNavLink {
    text-decoration: none;
    color: white;
    font-size: 18px;
}

.HeaderNavLink:hover {
    text-decoration: none;
    color: #ffd700;
    opacity: 1;
}

.HeaderCollapseContainer {
    justify-content: space-between;
}

.HeaderConnectWalletContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 30px;
    width: 12rem;
    background-color: blue;
}

.HeaderConnectWalletContainer :hover{
    opacity: .9;
    transition: 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.2);
    z-index: 999;
    overflow: hidden;
    /* padding: 4px;  */
}

.active {
    border-bottom: 3px solid white;
}

.navbar>.container-fluid {
    padding: 0
}

@media screen and (max-width: 992px) {

    .modalContainer {
        width: auto;
        background-color: #1B1124;
        color: white;
        overflow: hidden;
        font-size: 2vw;
    }

    .ErrorModalIcon {
        width: 3vw;
        height: 3vw;
    }

    .HeaderConnectWalletContainer {
        width: 40%;
    }

    .HeaderCollapseContainer {
        justify-content: center;
        flex-direction: column;
        display: flex;
    }
}


@media screen and (max-width: 768px) {
    .HeaderLogo {
        height: auto;
    }

    .HeaderNavItem {
        margin-bottom: 1vh;
        margin-top: 1vh;
    }

    .HeaderNavContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .HeaderCollapseContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .HeaderConnectWalletContainer {
        width: 100%;
        color: white;
    }

  
    .HeaderNavItem {
        height: 4vh;
        display: flex;
        align-items: center;
        font-size: 2vh;
    }

    .modalContainer {
        width: auto;
        background-color: #1B1124;
        color: white;
        overflow: hidden;
        font-size: 2.5vw;
    }

    .ErrorModalIcon {
        width: 4vw;
        height: 4vw;
    }
}

@media screen and (max-width: 576px) {
    .ErrorModalIcon {
        width: 5vw;
        height: 5vw;
    }

    .modalContainer {
        width: auto;
        background-color: #1B1124;
        color: white;
        overflow: hidden;
        font-size: 4vw;
    }

    .HeaderNavBarBrand {
        width: 25vw;
        height: auto;
    }
}