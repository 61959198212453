.HowItWorksTabContainer{
    background: rgba(62, 51, 71, 0.7);
    border-radius: 20px;
    min-height: 208px;
    padding: 20px; 
}
.NoBackgroundTabContainer{
    border-radius: 20px;
    min-height: 208px;
    padding: 20px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.HowitWorksContainer{
    min-height: 30vw;
}