.HomeMainContainer1{
    min-height: 40vh;
    display: flex;
    align-items: center;
}

.HomeMainContainer2{
    min-height: 20vh;
    display: flex;
    align-items: center;
}

.PSPadTitle{
  background: linear-gradient(180deg,#3b0f37,#8a2b81,#fefefe,#b095e3,#ccb8ed,#fefefe,#8a2b81,#3b0f37);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  font-size: 5vw;
}

.HomeMainHeading {
    font-size: 1.5vw;
    color: white;
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.HomeSubHeading {
    font-size: 2.7vh;
    color: #b0afb0;
    margin-top: 1vh;
    margin-bottom: 1vh;
}

@media screen and (max-width : 768px)
{
  .PSPadTitle{
    color: white;
    font-size: 10vw;
  }

    .HomeMainContainer1{
      text-align: center;
    }
    .HomeMainHeading
    {
      font-size: 4vw;
    }  
    .HomeSubHeading
    {
      font-size: 2.5vh;
    }
}

@media screen and (max-width : 576px)
{
    .PSPadTitle{
      font-size: 10vw;
    }

    .HomeMainHeading
    {
      font-size: 5vw;
    }  
    .HomeSubHeading
    {
      font-size: 2vh;
    }
    .HomeMainContainer1{
        height: 50vh;
    }
}