.UnstakingCheckpointsContainer {
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.UnstakingRoutesMainContainer {
  border: 2px solid rgba(62, 51, 71, 0.7);
  border-radius: 1.5vh;
  display: flex;
  flex-direction: column;
}
.setFontsize{
  font-size: 2.7vh;
}

.UnStakingInitializeContainer {
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin: 0.1vw;
  min-height: 20vh;
}


.UnStakingWarningContainer {
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin: 0.1vw;
  min-height: 30vh;
}

.UnstakingCheckpointsHeading {
  color: white;
  text-align: center;
  padding-bottom: 1vh;
  font-size: 1vw;
}

.UnstakingCheckpointsHeadingMain {
  color: white;
  text-align: center;
  padding-bottom: 1vh;
  font-size: 2.5vw;
}

.UnStakingHeading {
  color: #d7ccd9;
  font-size: 2.5vw;
  font-weight: bold;
}

.UnStakingAmountHeading {
  color: white;
  text-align: center;
  font-size: 1.8vw;
}

.UnStakingAmountSubHeading {
  color: white;
  text-align: center;
}

.UnStakingSuccessBox {
  display: initial;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: auto;
  width: auto;
}

.iconClass {
  display: flex;
  justify-content: center;
}

.InitializeUnstakeHeading {
  color: white;
  text-align: center;
  font-size: 1.5vw;
}

.InitializeUnstakeSubHeading {
  color: white;
  text-align: center;
  font-size: 0.8vw;
}

.StakingStatsContainer2 {
  padding: 1vh;
  border: 1px solid white;
  border-radius: 1.5vh;
  min-height: 35vh;
  margin-bottom: 2vh;
}

.SSC2SubContainer {
  border-radius: 1.5vh;
  background-color: rgba(62, 51, 71, 0.6);
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.SSC2SubContainer2 {
  width: 100%;
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SSC2StakedContainer {
  width: 70%;
  height: 6vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px dotted white;
  padding-bottom: 7vh;
}

.SSC2RewardContainer {
  width: 70%;
  height: 6vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 1vh;
}

.SSC2StakedHeading {
  color: #d7ccd9;
  font-weight: bold;
  font-size: 2vh;
}

.SSC2RewardHeading {
  color: #d7ccd9;
  font-weight: bold;
  font-size: 2vh;
}

.SSC2StakedAmount {
  color: #c361ba;
  font-weight: bold;
  font-size: 2vh;
}

.SSC2RewardAmount {
  color: #c361ba;
  font-weight: bold;
  font-size: 2vh;
}

.SSC2ButtonsContainer {
  min-height: 13vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.UnstakingConfirmationLink {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width : 768px) {
  .UnStakingHeading {
    font-size: 5vw;
  }

  .UnStakingAmountHeading {
    font-size: 8vw;
  }

  .UnstakingCheckpointsHeadingMain {
    font-size: 4vw;
  }

  .CheckpointsHeadingMain {
    font-size: 5vw;
  }
}

@media screen and (max-width : 576px) {
  .UnStakingHeading {
    font-size: 8vw;
  }

  .UnstakingRoutesMainContainer {
    margin-left: 1vw;
    margin-right: 1vw;
  }
}