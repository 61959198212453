.StakingStatsMainContainer {
  min-height: 11vh;
  border: 1px;
  border-radius: 1.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.StakingStatsSubContainer {
  background-color: rgba(62, 51, 71, 0.8);
  border-radius: 1.5vh;
  min-height: 9vh;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.StakingStatsHeading {
  border-bottom: 2px dotted rgba(255, 255, 255, 0.3);
  color: white;
  height: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  /* font-size: 1vw; */
  font-weight: bold;
}

.StakingStatsContent {
  color: #c361ba;
  display: flex;
  align-items: center;
  font-size: 2vh;
  width: 90%;
  height: 4vh;
  font-weight: bold;
}

@media screen and (max-width : 768px) {
  .StakingStatsHeading {
    justify-content: center;
  }

  .StakingStatsContent {
    justify-content: center;
  }
}

@media screen and (max-width : 576px) {
  .StakingStatsHeading {
    /* font-size: 4vw; */
    height: auto;
  }
}