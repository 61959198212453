.StakingStatusColContainer {
    padding: 0;
    display: flex;
    justify-content: center;
    padding-right: 0.5vw;
    padding-left: 0.5vw;
}

.StakingStatusMainContainer {
    background-color: rgba(62, 51, 71, 0.7);
    height: 11vh;
    border: 1px;
    border-radius: 1.5vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.StakingStatusHeading {
    color: white;
    height: 4vh;
    min-width: 70%;
    align-items: center;
    display: flex;
    justify-content: center;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.3);
    /* font-size: 1vw; */
    font-weight: bold;
}

.StakingStatusContent {
    color: #c361ba;
    /* font-size: 1vw; */
    font-weight: bold;
}

@media screen and (max-width : 768px) {
    .StakingStatusColContainer {
        padding: 1vh;
    }   
}

@media screen and (max-width : 576px) {
    .StakingStatusHeading {
        /* font-size: 4vw; */
        height: auto;
    }

    .StakingStatusContent {
        /* font-size: 3.5vw; */
    }
}