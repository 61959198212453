.StakingStatsContainer2New {
    padding: 1vh;
    border: 1px solid white;
    border-radius: 1.5vh;
    min-height: 35vh;
    margin-bottom: 2vh;
}


.SSC2SubContainerNew {
    border-radius: 1.5vh;
    background-color: rgba(62, 51, 71, 0.6);
    display: flex;
    min-height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}

.SSC2SubContainer2New {
    width: 100%;
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SSC2StakedContainerNew {
    width: 70%;
    height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px dotted white;
    padding-bottom: 7vh;
}

.SSC2StakedHeadingNew {
    color: #d7ccd9;
    font-weight: bold;
    font-size: 2vh;
}


.SSC2StakedAmountNew {
    color: #c361ba;
    font-weight: bold;
    font-size: 2vh;
}

.SSC2RewardContainerNew {
    width: 70%;
    height: 6vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 1vh;
}

.SSC2RewardHeadingNew {
    color: #d7ccd9;
    font-weight: bold;
    font-size: 2vh;
}

.SSC2RewardAmountNew {
    color: #c361ba;
    font-weight: bold;
    font-size: 2vh;
}


.SSC2ButtonsContainerNew {
    min-height: 10vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}