.StakingCheckpointMainContainer {
    background-color: rgba(62, 51, 71, 0.7);
    border-radius: 1.5vh;
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5vh;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
}

.StakingCheckpointHeading {
    color: white;
    font-size: 1vw;
    line-height: 4vh;
    font-weight: bold;
}

.StakingCheckpointSubHeading {
    color: white;
    line-height: 3vh;
    font-size: 1vw;
}

.StakingCheckpointIcon {
    color: white;
}

@media screen and (max-width : 1200px) {
    .StakingCheckpointHeading {
        font-size: 1.8vw;
    }
}
@media screen and (max-width : 1024px) {
    .StakingCheckpointSubHeading {
        font-size: 1.5vw;
    }
}

@media screen and (max-width : 768px) {
    .StakingCheckpointHeading {
        font-size: 3vw;
    }

    .StakingCheckpointSubHeading {
        color: white;
        line-height: 3vh;
        font-size: 2vw;
    }
}

@media screen and (max-width : 576px) {
    .StakingCheckpointSubHeading {
        font-size: 4vw;
    }
    .StakingCheckpointHeading {
        font-size: 5vw;
    }
    
}