.NextButtonMainContianer {
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
}

@media screen and (max-width: 576px) {
    .NextButtonMainContianer {
        flex-direction: column-reverse;
    }
}