.TabContainer{
    background: rgba(62, 51, 71, 0.7);
    border-radius: 20px;
    min-height: 336px;
    padding: 20px 
}

.LowerTabContainer{
    background: rgba(62, 51, 71, 0.7);
    border-radius: 20px;
    padding: 20px;
}

.FieldHeaderStyle{
    color: gold;
}

@media screen and (max-width : 767px)
{
    .TabContainer{
        min-height: 0;
    }
}