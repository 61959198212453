.AmountInputContainer{
    width: 30%;
    margin: 2vh;
}

.AmountInputButton {
    background-color: red;
    border: 0;
}

@media screen and (max-width : 720px)
{
    .AmountInputContainer{
        width: 60%;
    }
}