.CheckpointsContainer {
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.iconClass {
  display: flex;
  justify-content: center;
}

.stakingDirectionRow {
  height: "8vh";
  display: flex;
  justify-content: "center";
  align-items: "center"
}

.confirmStakingButtonBox {
  height: 10vh;
  width: 40vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #c361ba;
}

.stakingSuccessBox {
  display: initial;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: auto;
  width: auto;
}

.RoutesMainContainer {
  border: 2px solid rgba(62, 51, 71, 0.7);
  border-radius: 1.5vh;
  display: flex;
  flex-direction: column;
}

.StakingAmountContainer {
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin: 0.1vw;
  min-height: 30vh;
}

.StakingAmountContainerStep5 {
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin: 0.1vw;
  min-height: 20vh;
}

.PreAuthAmountContainer {
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin: 0.1vw;
  min-height: 30vh;
}

.CheckpointsHeading {
  color: white;
  text-align: center;
  padding-bottom: 1vh;
  font-size: 1vw;
}

.CheckpointsHeadingMain {
  color: white;
  text-align: center;
  padding-bottom: 1vh;
  font-size: 2.5vw;
}


.StakingHeading {
  color: #d7ccd9;
  /* font-size: 2.5vw; */
  font-weight: bold;
}

.StakingStatsMainContainer1 {
  min-height: 11vh;
  border: 1px;
  border-radius: 1.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.StakingStatsSubContainer1 {
  background-color: rgba(62, 51, 71, 0.8);
  border-radius: 1.5vh;
  min-height: 9vh;
  width: 95%;
}

.RewardsContainer {
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.StakingAmountHeading {
  color: white;
  text-align: center;
  /* font-size: 1.8vw; */
}

.StakingAmountSubHeading {
  color: white;
  text-align: center;
}

.PreAuthHeading {
  color: white;
  text-align: center;
  /* font-size: 1.5vw; */
}

.PreAuthSubHeading {
  color: white;
  text-align: center;
  /* font-size: 0.8vw; */
}

.StakingConfirmationLink {
  cursor: pointer;
  color: white;
  text-decoration: underline;
}

@media screen and (max-width : 768px) {
  .StakingHeading {
    /* font-size: 5vw; */
  }

  .StakingAmountHeading {
    /* font-size: 8vw; */
  }

  .CheckpointsHeading {
    /* font-size: 4vw; */
      color: white;
      text-align: center;
      padding-bottom: 1vh;
      font-size: 4vw;
  }

  .CheckpointsHeadingMain {
    /* font-size: 5vw; */
  }
}

@media screen and (max-width : 576px) {
  .StakingHeading {
    /* font-size: 8vw; */
  }

  .RoutesMainContainer {
    margin-left: 1vw;
    margin-right: 1vw;
  }
}