.app-background {
    background-image: url("./Assets/appBackground.png");
    background-color: #1B1124;
    background-repeat: no-repeat;
    background-position: initial;
    background-size: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    font-family: "Verdana";
    min-height: 100vh;
}

.app-background>.row {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
}

.app-background>.col {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
}

.Heading {
    color: white;
}

.tab-pane.active {
    border-bottom: 0;
}

@font-face {
    font-family: "CenturyGothic";
    src: url('../src/Assets/Fonts/CenturyGothic.ttf');
    font-style: normal;
    font-weight: 400
}

/* verdana */
@font-face {
    font-family: "Verdana";
    src: url('../src/Assets/Fonts/verdana.ttf');
    font-style: normal;
    font-weight: 400
}